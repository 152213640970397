import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, StyleSheet, Text, Dimensions, Modal, TouchableWithoutFeedback, Image } from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { StackActions, useNavigation } from '@react-navigation/native';
import { getPromptCategoriesAction, setPromptTitle } from "../../actions/tasks";
import { useDispatch, useSelector } from "react-redux";
import colors from "../../constants/colors";
import { MaterialIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Manufacturing from "../../../assets/Icons/precision_manufacturing.svg";
import Air from "../../../assets/Icons/air.svg";

const screenHeight = Dimensions.get('window').height;
const buttonHeight = 60; // Height of your button
const initialTopPosition = screenHeight - 2.3 * buttonHeight;

const FloatingMenu = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const cats = useSelector(state => state.tasks.get('availableCategories'));
  const defaultCategories = useSelector(state => state.tasks.get('categories'));
  const prompts = useSelector(state => state.tasks.get('prompts'));
  const default_prompt = useSelector(state => state.tasks.get('default_prompt'));

  const [modalVisible, setModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const topPosition = initialTopPosition;
  const [menuPosition, setMenuPosition] = useState(topPosition);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryIcon, setSelectedCategoryIcon] = useState("");
  const [submenuPrompts, setSubmenuPrompts] = useState([]);

  const handleCategoryClick = (category, icon) => {
    if (category == 'ai_chatbot') {
      setModalVisible(false);
      if (default_prompt) {
        dispatch(setPromptTitle(default_prompt.name));
        setTimeout(() => {
          const pushAction = StackActions.push('PromptFormView', { promptMessages: default_prompt?.promptMessages, form: default_prompt });
          navigation.dispatch(pushAction);
        }, 200);
      }
      return;
    }
    setSelectedCategory(category);
    setSelectedCategoryIcon(icon);
  }
  const handlePromptClick = (prompt) => {
    setModalVisible(false);
    setSelectedCategory("");
    setSelectedCategoryIcon("");
    setSubmenuPrompts([]);

    dispatch(setPromptTitle(prompt.name));
    setTimeout(() => {
      const pushAction = StackActions.push('PromptFormView', { promptMessages: prompt?.promptMessages, form: prompt });
      navigation.dispatch(pushAction);
    }, 200);
  }
  const handleSubmenuClick = () => {
    setModalVisible(false);
    navigation.navigate("SubmenuView", {});

  }
  const handleBackClick = () => {
    setSelectedCategory("");
    setSelectedCategoryIcon("");
    setSubmenuPrompts([]);
  }

  useEffect(() => {
    dispatch(getPromptCategoriesAction((response) => { }));
    window.addEventListener('scroll', () => {
      var y = window.scrollY;
      setMenuPosition(topPosition + y);
    });
  }, []);

  useEffect(() => {
    const availableCategories = cats.map(elem => {
      return defaultCategories.find(cat => cat.value == elem)
    });

    setCategories(availableCategories);
  }, [cats, defaultCategories]);

  useEffect(() => {
    const availablePrompts = prompts.filter(elem => elem?.promptMessages?.category == selectedCategory);
    setSubmenuPrompts(availablePrompts);
  }, [selectedCategory]);

  useEffect(() => {
  }, [submenuPrompts]);

  const validateCategory = (category) => {
    var valid = false;
    const availablePrompts = prompts.filter(elem => elem?.promptMessages?.category == category);
    if (availablePrompts?.length > 0) {
      valid = true;
    }

    return valid;
  }
  const getIcon = (icon) => {
    switch (icon) {
      case "list-alt":
      case "calendar":
      case "check-square":
      case "flask":
      case "calculator":
      case "cogs":
      case "sort-numeric-asc":
      case "ship":
      case "download":
      case "line-chart":
        return <Icon name={icon} size={30} color="#fff" />;
      case "Recycling":
        return <FontAwesome5 name="trash" size={30} color="#fff" />;
      case 'AccessAlarm':
        return <MaterialIcons name="access-alarm" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'Assessment':
        return <MaterialIcons name="assessment" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'AttachMoney':
        return <MaterialIcons name="attach-money" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'Badge':
        return <MaterialIcons name="badge" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'Biotech':
        return <MaterialIcons name="biotech" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'Build':
        return <Ionicons name="build" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'LocalShipping':
        return <MaterialIcons name="local-shipping" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'CleanHands':
        return <MaterialIcons name="clean-hands" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Contacts':
        return <MaterialIcons name="contacts" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Cookie':
        return <MaterialCommunityIcons name="cookie" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Dining':
        return <MaterialIcons name="local-dining" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Email':
        return <MaterialIcons name="email" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'EditNote':
        return <MaterialCommunityIcons name="note-edit-outline" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Engineering':
        return <MaterialIcons name="engineering" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'EnhancedEncryption':
        return <MaterialIcons name="enhanced-encryption" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'ElectricalServices':
        return <MaterialIcons name="electrical-services" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'FireExtinguisher':
        return <MaterialIcons name="fire-extinguisher" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'FireHydrantAlt':
        return <MaterialCommunityIcons name="fire-hydrant" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'FindInPage':
        return <MaterialIcons name="find-in-page" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'FilterAlt':
        return <MaterialIcons name="filter-alt" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Folder':
        return <MaterialIcons name="folder" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'FollowTheSigns':
        return <MaterialIcons name="follow-the-signs" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Groups':
        return <MaterialIcons name="groups" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'HelpOutline':
        return <MaterialIcons name="help-outline" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Language':
        return <MaterialIcons name="language" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'MenuBook':
        return <MaterialIcons name="menu-book" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'OnDeviceTraining':
        return <MaterialCommunityIcons name="cellphone-information" size={30} color="#fff" style={{height: 30,width: 30,color: "#fff"}}/>

      case 'PestControlRodent':
        return <MaterialIcons name="pest-control-rodent" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Plumbing':
        return <MaterialIcons name="plumbing" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Place':
        return <MaterialIcons name="place" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'Public':
        return <MaterialIcons name="public" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'Report':
        return <MaterialIcons name="report" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'RestoreFromTrash':
        return <MaterialIcons name="restore-from-trash" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Scale':
        return <MaterialCommunityIcons name="scale" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case "School":
        return <Ionicons name="school" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'Straighten':
        return <MaterialIcons name="straighten" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'TabletAndroid':
        return <MaterialIcons name="tablet-android" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Thermostat':
        return <MaterialIcons name="device-thermostat" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Warehouse':
        return <MaterialCommunityIcons name="warehouse" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Visibility':
        return <MaterialIcons name="visibility" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'WaterDrop':
        return <MaterialCommunityIcons name="water" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'WaterDrop':
        return <MaterialCommunityIcons name="water" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'ShoppingBag':
        return <MaterialIcons name="shopping-bag" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'AcUnit':
        return <MaterialIcons name="ac-unit" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Air':
        return <Image source={{uri: Air}} size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Apartment':
        return <MaterialIcons name="apartment" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'CarRepair':
        return <MaterialIcons name="car-repair" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'CellTower':
        return <MaterialCommunityIcons name="radio-tower" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Chat':
        return <MaterialIcons name="chat" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Computer':
        return <MaterialIcons name="computer" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'DeliveryDining':
        return <MaterialIcons name="delivery-dining" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'EmojiObjects':
        return <MaterialIcons name="emoji-objects" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Fingerprint':
        return <MaterialIcons name="fingerprint" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Handshake':
        return <MaterialCommunityIcons name="handshake" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Https':
        return <MaterialIcons name="https" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Info':
        return <MaterialIcons name="info" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Image':
        return <MaterialIcons name="image" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'LocalCafe':
        return <MaterialIcons name="local-cafe" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Liquor':
        return <MaterialIcons name="liquor" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Masks':
        return <MaterialIcons name="masks" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Man':
        return <MaterialCommunityIcons name="human-male" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'PointOfSale':
        return <MaterialIcons name="point-of-sale" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Psychology':
        return <MaterialIcons name="psychology" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'QueryStats':
        return <Icon name="line-chart" size={30} color="#fff" />;

      case 'RoomService':
        return <MaterialIcons name="room-service" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Router':
        return <MaterialIcons name="router" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'Sanitizer':
        return <MaterialIcons name="sanitizer" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'SupportAgent':
        return <MaterialIcons name="support-agent" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'VerifiedUser':
      case "GppGood":
        return <MaterialIcons name="verified-user" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'WarningAmber':
        return <MaterialIcons name="warning" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'ThumbDownOffAlt':
        return <MaterialIcons name="thumb-down" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />

      case 'ThumbUpOffAlt':
        return <MaterialIcons name="thumb-up" size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />
      case 'PrecisionManufacturing':
        return <Image source={{uri: Manufacturing}} size={30} color="#fff" style={{ height: 30, width: 30, color: "#fff" }} />


    }
  }

  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(!modalVisible)}
      >
        <TouchableWithoutFeedback onPress={() => {
          setModalVisible(!modalVisible);
          setSelectedCategory("");
          setSelectedCategoryIcon("");
          setSubmenuPrompts([]);
        }}>
          <View style={styles.modalView}>
            <View style={styles.centeredView}>
              <View style={{...styles.containingBox, marginBottom: selectedCategory ? 10 : 40 }}>
                {
                  !selectedCategory ?
                    <>
                      {
                        categories.map(category => (
                          validateCategory(category.value) &&
                          <TouchableOpacity
                            key={category.key}
                            style={styles.menuButton}
                            onPress={() => handleCategoryClick(category.value, category.icon)}
                          >
                            {getIcon(category.icon)}
                            <Text style={styles.menuText}>{category.label}</Text>
                          </TouchableOpacity>
                        ))
                      }
                      {
                        default_prompt &&
                        <TouchableOpacity
                          style={styles.menuButton}
                          onPress={() => handleCategoryClick('ai_chatbot', '')}
                        >
                          <Image style={{ height: 40, width: 40 }} source={require("../../../assets/iQOps-white_ai-icon_ai-icon.svg")} />
                          <Text style={styles.menuText}>AI</Text>
                        </TouchableOpacity>
                      }

                    </>
                    : submenuPrompts.map(prompt => (
                      <TouchableOpacity
                        key={prompt._id}
                        style={styles.menuButton}
                        onPress={() => handlePromptClick(prompt)}
                      >
                        {getIcon(prompt?.promptMessages?.icon ? prompt?.promptMessages?.icon : selectedCategoryIcon)}
                        <Text style={styles.menuText}>{prompt.name}</Text>
                      </TouchableOpacity>
                    ))
                }
                {
                  selectedCategory &&
                  <TouchableOpacity
                    style={styles.menuButton}
                    onPress={() => handleBackClick()}
                  >
                    <Icon name={'arrow-left'} size={30} color="#3586e3" />
                  </TouchableOpacity>
                }
                {
                  defaultCategories?.length > 0 && !selectedCategory &&
                  <>
                    <TouchableOpacity
                      style={styles.menuButton}
                      onPress={() => handleSubmenuClick()}
                    >
                      <Icon name={'plus'} size={30} color="#3586e3" />
                    </TouchableOpacity>
                  </>
                }
              </View>
              <View>
                <Text style={styles.categorySelectedTxt}>
                  {
                    selectedCategory && defaultCategories.map((category) => {
                      if(category.value == selectedCategory){
                        return category.label;
                      }
                    }) 
                  }
                </Text>
              </View>

            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <View style={[styles.container, { top: menuPosition }]} >
        <TouchableOpacity style={styles.button} onPress={() => setModalVisible(true)}>
          <Icon name="check-circle" size={55} color="#FFFF" backgroundColor="#249BFA" />
        </TouchableOpacity>
      </View>
    </>
  )
}

export default FloatingMenu;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    right: 10,
    zIndex: 10,
  },
  button: {
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00509B',
  },
  text: {
    fontSize: 30,
    color: 'white',
  },
  centeredView: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: '100%',
    width: '100%',
  },
  containingBox: {
    width: 300,
    // height: 350,
    backgroundColor: colors.primary,
    borderRadius: 30,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'row', // Layout children in rows
    flexWrap: 'wrap', // Allow wrapping of children
    // justifyContent: 'center', // Center children horizontally
    // alignItems: 'center' // Center children vertically
  },
  menuButton: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    marginVertical: 10,
    // justifyContent: 'center', // Center content inside each item
    alignItems: 'center',
    width: '33.33%'
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  menuText: {
    color: "#fff",
    textAlign: 'center'
  },
  categorySelectedTxt:{
    fontSize: 20,
    marginBottom: 30,
    color: "#fff"
  }
});
